interface Instance {
  metaSiteId?: string;
  appDefId?: string;
  biToken?: string;
  instanceId?: string;
  aid?: string;
  siteOwnerId?: string;
  siteIsTemplate?: boolean;
  uid?: string;
}

const getInstance = (instance: string): Instance => {
  try {
    return JSON.parse(atob(instance.split('.')[1]));
  } catch (e) {
    return {};
  }
};

export const isTemplateView = (instance: string) => {
  const signedInstance = getInstance(instance);
  return signedInstance?.siteIsTemplate ?? false;
};
