import { create } from '@wix/fedops-logger';
import {
  HOTEL_RUNNER_APP_ID,
  STAGING_HOTELS_RUNNER_APP_ID,
} from '../../app-specific-logic/hotels/hotels.constants';
import type { ViewerControllerProps } from '../../types/viewer';
import { initViewerGlobals } from '../../utils/globals.utils';
import { isTemplateView } from '../../utils/is-template-view';

export default ({ controllerConfig }: ViewerControllerProps) => {
  const { $w, wixCodeApi, compId, platformAPIs, appParams } = controllerConfig;
  const { appDefinitionId, instance, instanceId } = appParams;

  initViewerGlobals({
    client: controllerConfig.essentials.httpClient,
    instance: instanceId,
  });

  const isTemplate =
    wixCodeApi?.window?.viewMode === 'Preview' && isTemplateView(instance);

  const isHotels =
    appDefinitionId === HOTEL_RUNNER_APP_ID ||
    appDefinitionId === STAGING_HOTELS_RUNNER_APP_ID;
  const viewMode =
    isHotels && isTemplate
      ? 'Template'
      : wixCodeApi?.window?.viewMode ?? 'Preview';
  const formFactor = wixCodeApi?.window?.formFactor ?? 'Desktop';
  const deviceType = wixCodeApi.window.formFactor ?? 'Desktop';
  const fedopsLogger = create('web-component-wrapper-logs');
  const widgetLogger = platformAPIs.fedOpsLoggerFactory?.getLoggerForWidget({
    appId: appDefinitionId,
    widgetId: compId,
  });

  return {
    pageReady: async () => {
      if ($w('@webComponent').length !== 0) {
        $w('@webComponent').on('startPayment', async (event: any) => {
          fedopsLogger.interactionStarted('new-wcw-payments');
          const interactionName = 'wcw-startpayment';
          widgetLogger?.interactionStarted(interactionName);
          // @ts-expect-error
          const result = await wixCodeApi.pay.startPayment(
            event.detail.orderId,
            {
              showThankYouPage: false,
              userInfo: event.detail.userInfo,
              skipUserInfoPage: true,
            },
          );
          // @ts-expect-error
          wixCodeApi.window.trackEvent(`${event.compId}|payments`, {
            detail: result,
          });
          fedopsLogger.interactionEnded('new-wcw-payments');
          widgetLogger?.interactionEnded(interactionName);
        });

        const { pages } = await wixCodeApi.site.getSiteStructure({
          includePageId: true,
        });

        // TODO: get rid of needing to write all wixconfig again just for certain props

        const stringified = JSON.stringify({
          viewMode,
          instanceId: appParams.instanceId,
          compId,
          deviceType,
          pages,
          authorization:
            wixCodeApi?.site?.getAppToken &&
            wixCodeApi?.site?.getAppToken(appParams.appDefinitionId),
          formFactor,
        });

        $w('@webComponent').setAttribute('wixconfig', stringified);
        $w('@webComponent').on('navigateTo', (event1: any) => {
          const { page } = event1.detail;

          if (wixCodeApi.location.to) {
            let path = `/${page}`;
            if (page === 'home') {
              path = '/';
            }
            wixCodeApi.location.to(path);
          }
        });

        $w('@webComponent').on('web-component-event', (event2: any) => {});
      }
    },
  };
};
